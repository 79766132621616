import axios from 'axios'
import { Notify } from 'vant';
import { getSign, createNonce } from './util'
import qs from 'qs'
import apiKeys from './apiKeys'

/**
 * 这里是所有环境的api地址前缀
 */
const ALL_ENV_BASEURL = {
	local: 'http://10.10.1.104:8891',
  dev: 'https://api.dev.ysintelligent.com/ia',
	test: 'https://api.test.ysintelligent.com/ia',
	prod: 'https://api.ysintelligent.com/ia'
}

/**
 * 所有环境的oss资源地址前缀
 */
const ALL_ENV_OSSURL = {
	local: 'https://oss.dev.yijiajiayun.com',
	dev: 'https://oss.dev.yijiajiayun.com',
	test: 'https://oss.test.yijiajiayun.com',
	prod: 'https://oss.yijiajiayun.com'
}

export const baseURL = ALL_ENV_BASEURL[process.env.VUE_APP_API_ENV]
export const ossURL = ALL_ENV_OSSURL[process.env.VUE_APP_API_ENV]

/**
 * 接口请求统一处理
 * @param {*} baseURL 
 * @param {*} accessKey 
 * @param {*} secretKey 
 * @returns 
 */
function createRequest(baseURL, accessKey, secretKey) {
	// 创建axios实例
	const request = axios.create({
		// API 请求的默认前缀
		baseURL: baseURL,
		// 请求超时时间
		timeout: 15000,

		headers: {
			'Accept': '*/*',
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
		}
	})

	request.interceptors.request.use(config => {
		// 在发送请求之前做些什么
		// console.log(config)
		const timestamp = Date.now()
		const nonce = createNonce()

		let signObj
		if (config.method === 'get') {
			const params = switchParams(config.params)
			config.params = {
				...params
			}
			signObj = {
				...config.params,
				'X-validate-accessKey': accessKey,
				'X-validate-timestamp': timestamp,
				'X-validate-nonceStr': nonce
			}
		} else {
			const data = switchParams(config.data)
			config.data = {
				...data
			}
			signObj = {
				...config.data,
				'X-validate-accessKey': accessKey,
				'X-validate-timestamp': timestamp,
				'X-validate-nonceStr': nonce
			}
		}

		const sign = getSign(signObj, secretKey)
		config.headers['X-validate-accessKey'] = accessKey
		config.headers['X-validate-timestamp'] = timestamp
		config.headers['X-validate-nonceStr'] = nonce
		config.headers['X-validate-sign'] = sign

		if (config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') >= 0 && config.data) {
			config.data = qs.stringify(config.data)
		}
		// console.log(config)
		return config
	}, error => {
		// 对请求错误做些什么
		return Promise.reject(error)
	})

	request.interceptors.response.use(response => {
		// console.log(response)
		// 对响应数据做点什么
		// response.data.code
		if (!response.data) {
			Notify('网络异常，请稍后重试')
		}
		return response.data
	}, error => {
		// 对响应错误做点什么
		Notify('网络异常，请稍后重试')
		return Promise.reject(error)
	})

	return request
}

/**
 * 过滤参数
 * @param {*} params 
 * @returns 
 */
function switchParams(params) {
	// console.log('switchParams', params)
	const paramsObj = {}
	for (const key in params) {
		const value = params[key]
		if (value !== null && value !== undefined) {
			paramsObj[key] = value
		}
	}
	return paramsObj
}

const request = createRequest(baseURL, apiKeys.accessKey, apiKeys.secretKey)

export default request


