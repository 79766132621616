import Vue from 'vue'
import Vuex from 'vuex'
import { iaActivityConfigDetail } from '@/api'
import { Toast } from 'vant'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    activityId: '',
    activityConfig: {}, // 活动配置
    fieldList: [], // 表单字段列表
    invitationContent: '', // 邀请函内容
  },
  mutations: {
    setActivityId(state, activityId) {
      state.activityId = activityId
    },
    setActivityConfig(state, activityConfig) {
      state.activityConfig = activityConfig
    },
    setFieldList(state, fieldList) {
      state.fieldList = fieldList
    },
    setInvitationContent(state, invitationContent) {
      state.invitationContent = invitationContent
    }
  },
  actions: {
    getActivityDetail({commit}, data){
      return new Promise((resolve,reject) => {
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中'
        })
        iaActivityConfigDetail({id: data.id}).then(res => {
          toast.clear()
          if (res.code === 200 && res.data) {
            let [config, issueList] = res.data.configIssueList
            commit('setActivityId', res.data.id)
            commit('setActivityConfig', config || {})
            commit('setFieldList', issueList || [])
            commit('setInvitationContent', res.data.content || '')
            resolve(res)
          } else {
            reject(res)
            Toast(res.msg || '网络错误')
          }
          
        }).catch(err => {
          toast.clear()
          console.error(err)
          reject(err)
        })
      })
      
    }
  }
})

export default store