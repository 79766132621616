import apiKeys from './apiKeys'
import { ossURL } from './request'
import md5 from 'md5'
import Thumbor from 'thumbor-js-url-builder'

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword(pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}


/**
 * 防抖函数
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function debounce(fn, delay = 300) {
  let timer = null
  return function () {
    const ctx = this
    const args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(ctx, args)
    }, delay)
  }
}

/**
 * 节流函数
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function throttle(fn, delay = 1000) {
  let timer = null
  return function () {
    const ctx = this
    const args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(ctx, args)
        timer = null
      }, delay)
    }
  }
}

/**
 * 深拷贝
 * @param {*} source 
 * @returns 
 */
export function deepClone(source) {
  if (!source) return
  let target
  if (typeof source === 'object') {
    // 根据source类型判断是新建一个数组还是对象
    target = Array.isArray(source) ? [] : {}
    // 遍历source，并且判断是source的属性才拷贝
    for (const key in source) {
      // source.hasOwnProperty(key)
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (typeof source[key] !== 'object') {
          target[key] = source[key]
        } else {
          // 如果内部属性存在复杂数据类型，使用递归实现深拷贝
          target[key] = deepClone(source[key])
        }
      }
    }
  } else {
    target = source
  }
  return target
}

/**
 * 签名算法 - java接口
 * @param {*} obj 
 * @param {*} secretKey 
 * @returns 
 */
export function getSign(obj, secretKey) {
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    let str = ''
    const sortArr = Object.keys(obj).sort()
    sortArr.forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
        // console.log(key, obj[key])
        let value = obj[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        str += key + '=' + value + '&'
      }
    })
    str = str + 'secretKey=' + secretKey
    // console.log('str', str)
    // console.log(md5(str).toUpperCase())
    return md5(str).toUpperCase()
  }
  return ''
}


/**
 * 生成32位随机数的方法
 * @returns 
 */
export function createNonce() {
  const arr = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
  ]
  let str = ''
  const L = arr.length - 1
  for (let i = 0; i < 32; i++) {
    str += arr[getRandomIntInclusive(0, L)]
  }
  return str
}

/**
 * 得到一个两数之间的随机整数，包括两个数在内
 * @param {*} min 
 * @param {*} max 
 * @returns 
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min //含最大值，含最小值 
}

/**
 * 生成java接口所属的header参数
 * @param {*} params 
 * @returns 
 */
export function createHeaders(params) {
  // 上传header参数预处理
  const timestamp = Date.now()
  const nonce = createNonce()
  const signObj = {
    'X-validate-accessKey': apiKeys.accessKey,
    'X-validate-timestamp': timestamp,
    'X-validate-nonceStr': nonce,
    ...params
  }
  const sign = getSign(signObj, apiKeys.secretKey)

  return {
    'X-validate-accessKey': apiKeys.accessKey,
    'X-validate-timestamp': timestamp,
    'X-validate-nonceStr': nonce,
    'X-validate-sign': sign,
  }
}

/**
 * minIO 图片上传: 后端返回地址 => 前端展示地址
 * @param {*} url 
 * @param {*} config 
 * @returns 
 */
export function buildThumborUrl(url, config) {
  // 是否返回安全地址
  const SECURITY = true
  // 初始化Thumbor实例
  const thumbor = new Thumbor(apiKeys.imageSecurityKey, ossURL)

  if (!url || typeof url !== 'string') {
    console.warn('The url is required and it should be a string')
    return
  }

  config = config || {}

  if (config.width && isNaN(config.width)) {
    console.warn('The width should be a number')
    return
  }
  if (config.height && isNaN(config.height)) {
    console.warn('The height should be a number')
    return
  }

  const width = config.width || 1000
  const height = config.height || 0

  if (SECURITY) {
    return thumbor
      .setImagePath(url)
      .resize(width, height)
      .buildUrl()
  }
  url = url.charAt(0) === '/' ? url.substring(1) : url
  return `${ossURL}/unsafe/${width}x${height}/${url}`
}

/**
 * 判断数组里是否有重复元素
 * @param {*} arr 
 * @returns 
 */
export function isRepeat(arr) {
  var hash = {}
  for (var i in arr) {
    if (hash[arr[i]]) {
      return true
    }
    // 不存在该元素，则赋值为true
    hash[arr[i]] = true
  }
  return false
}


/**
 * file => base64
 * @param {*} file 
 * @returns 
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/**
 * 获取到指定定位元素的offsetLeft/offsetTop
 * @param {*} node 当前元素
 * @param {*} dir left / top
 * @param {*} targetNode 指定父级定位元素
 * @returns 
 */
export function getOffsetDistance(node, dir, targetNode) {
  let dis = 0
  const props = { left: 'offsetLeft', top: 'offsetTop' }
  dir = props[dir] || 'offsetLeft'
  targetNode = targetNode || document.getElementById('app')
  function getDis(node, dir, targetNode) {
    dis += node[dir]
    if (node.offsetParent && node.offsetParent != targetNode) {
      getDis(node.offsetParent, dir, targetNode)
    }
  }
  getDis(node, dir, targetNode)
  return dis
}
