import Vue from 'vue'
import { 
  Button,
  Tab, 
  Tabs,
  Icon,
  Toast,
  Empty,
  Picker,
  DatetimePicker,
  Popup,
  Area,
  Uploader,
  Overlay
} from 'vant'

Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Empty)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Uploader)
Vue.use(Overlay)