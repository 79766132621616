const env = process.env.VUE_APP_API_ENV

let apiKeys

if (env === 'local') {
  apiKeys = {
    accessKey: '0000000',                           // java接口accessKey
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',  // java接口secretKey
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',       // 图片服务安全key
    amapWebKey: 'cecf529cbd695c8bd798a3830bd8340c', // 高德地图web服务key
  }
}

if (env === 'dev') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',
    amapWebKey: 'cecf529cbd695c8bd798a3830bd8340c',
  }
}

if (env === 'test') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    imageSecurityKey: 'tqZcNGVyGdVPpQmrqzrD',
    amapWebKey: 'cecf529cbd695c8bd798a3830bd8340c',
  }
}

if (env === 'prod') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    imageSecurityKey: 'xjwXvByGleYjRZOuu5dO',
    amapWebKey: 'cecf529cbd695c8bd798a3830bd8340c',
  }
}

export default apiKeys
