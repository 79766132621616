import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'invitation',
    component: () => import('../views/Invitation.vue'),
    meta: {
      title: '邀请函'
    }
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/Questionnaire.vue'),
    meta: {
      title: '问卷表单'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/Success.vue'),
    meta: {
      title: '问卷表单-提交成功'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from, next) => {
  // console.log('beforeEach', to, from)
  if (to.name === 'invitation' || to.name === 'questionnaire') {
    if (store.state.activityId) { 
      next() 
    } else {
      store.dispatch('getActivityDetail', {id: to.query.id}).then(res => {
        console.log(res)
        next()
      }).catch(err => {
        console.error(err)
        next()
      })
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  document.title = to.meta.title
})

export default router
