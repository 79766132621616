/**
 * 活动管理相关接口
 */
 import request from '../utils/request'

 /**
 * 活动配置详情
 * @param {*} parameter 
 * @returns 
 */
export function iaActivityConfigDetail(parameter) {
  return request({
    url: '/form/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 活动 - 表单提交
 * @param {*} parameter 
 * @returns 
 */
 export function iaActivityFormSave(parameter) {
  return request({
    url: '/formData/save',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


